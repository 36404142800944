import CourseRepository from './controllers/CourseRepository'
import FeedbackRepository from './controllers/FeedbackRepository'
import GradeRepository from './controllers/GradeRepository'
import LanguageRepository from './controllers/LanguageRepository'
import LearnerRepository from './controllers/LearnerRepository'
import LicenseRepository from './controllers/LicenseRepository'
import ResourceRepository from './controllers/ResourceRepository'
import UserRepository from './controllers/UserRepository'

const repositories = {
    course: CourseRepository,
    feedback: FeedbackRepository,
    grade: GradeRepository,
    language: LanguageRepository,
    learner: LearnerRepository,
    license: LicenseRepository,
    resource: ResourceRepository,
    user: UserRepository
};

export const RepositoryFactory = {
    get: name => repositories[name]
};
