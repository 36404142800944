import Repository from '@/repositories/Repository'

export default {

    getSnapshot(learnerId, resourceId, scoreId) {
        var response = Repository.get('/learner/getsnapshot', {
            params:{ 
                learnerid: learnerId,
                resourceid: resourceId,
                scoreid: scoreId     
            } 
        });
        return response;
    },

    listLearners(page, itemsPerPage, search) {
        var response = Repository.get('/learner/listlearners', {
                params:{
                    page: page,
                    itemsperpage: itemsPerPage,
                    search: search            
                } 
            });
        return response;
    },

    listScores(page, itemsPerPage, search) {
        var response = Repository.get('/learner/listscores', {
                params:{
                    page: page,
                    itemsperpage: itemsPerPage,
                    search: search            
                } 
            });
        return response;
    },

    logProgress(learnerId, courseId, courseItemId) {
        var response = Repository.post('/learner/logprogress', {
            learnerid: learnerId,
            courseid: courseId,
            courseitemid: courseItemId
        });
        return response;
    },

    registerLearner(firstname, surname, languageId, gradeId) {
        var response = Repository.post('/learner/registerlearner', {
            firstname: firstname,
            surname: surname,
            languageid: languageId,
            gradeid: gradeId
        });
        return response;
    },

    writeScore(scoreId, learnerId, courseId, courseItemId, resourceId, total, correct, snapshot) {
        var response = Repository.post('/learner/writescore', {
            scoreid: scoreId,
            learnerid: learnerId,
            courseid: courseId,
            courseitemid: courseItemId,
            resourceid: resourceId,
            total: total,
            correct: correct,
            snapshot: snapshot
        });
        return response;
    },    
    
};
