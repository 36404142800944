import Repository from '@/repositories/Repository'

export default {

    closeFeedback(feedbackId) {
        var response = Repository.post('/feedback/closefeedback', {
            feedbackid: feedbackId
        });
        return response;
    },

    listFeedback(page, itemsPerPage, search) {
        var response = Repository.get('/feedback/listfeedback', {
                params:{
                    page: page,
                    itemsperpage: itemsPerPage,
                    search: search 
                } 
            });
        return response;
    },

    submitMessage(learnerId, route, message) {
        var response = Repository.post('/feedback/submitmessage', {
            learnerid: learnerId,
            route: route,
            message: message
        });
        return response;
    },

};
