import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  
  {
    path: '/',
    component: () => import('@/views/SignIn'), 
    meta: {
      requiresAuth: false
    },        
  },

  { 
    path: '/content', 
    component: () => import('@/views/Content'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/course/:courseid/:parentid', 
    component: () => import('@/views/Course'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/learneractivity', 
    component: () => import('@/views/LearnerActivity'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/exercise/:resourceid', 
    component: () => import('@/views/Exercise'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/feedback', 
    component: () => import('@/views/Feedback'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/flipbook/:resourceid', 
    component: () => import('@/views/Flipbook'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/issuelicense', 
    component: () => import('@/views/IssueLicense'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/learners', 
    component: () => import('@/views/Learners'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/licenses', 
    component: () => import('@/views/Licenses'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/pdf/:resourceid', 
    component: () => import('@/views/PDF'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/registerlearner', 
    component: () => import('@/views/RegisterLearner'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/resources', 
    component: () => import('@/views/Resources'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/uploadresource', 
    component: () => import('@/views/UploadResource'), 
    meta: {
      requiresAuth: true
    },    
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if ( to.matched.some(record => record.meta.requiresAuth) ) {
    
    // this route requires auth, check if logged in
    // if not, redirect to sign in page.
    if (!store.state.auth.authenticated) {
      next({
        path: '/',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }

  } else {
    if (to.path == '/') {
      if (store.state.auth.authenticated) {
        next({
          path: '/dashboard',
          query: { redirect: to.fullPath }
        })        
      } else {
        next()
      }
    } else {
      next() // make sure to always call next()!
    }
  }
});

export default router
