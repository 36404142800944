<template>
  <v-app
    v-bind:class="{ frontpage: !$store.state.auth.authenticated, backdrop: $store.state.auth.authenticated }"
  >
    <v-app-bar
      app
      clipped-left
    >
      <div class="d-flex align-center">

        <v-app-bar-nav-icon
          v-if="($store.state.auth.authenticated) && ($store.state.auth.user.role.roleid !== 3)"
          @click="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <v-img
          alt="Khula Logo"
          src="@/assets/weblogo.png"
        />

      </div>

      <v-spacer></v-spacer>

      <v-btn
        v-if="$store.state.auth.authenticated"      
        fab
        small
        @click="showFeedbackDialog"
        style="color: #4285f4;"
      >
        <v-icon>
          mdi-chat
        </v-icon>  
      </v-btn>

      <v-menu 
        v-if="!contentView && $store.state.auth.authenticated && ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
        offset-y
      >

        <template v-slot:activator="{ on, attrs }">
          <v-btn             
            fab
            small 
            class="ma-2 btn"
            v-bind="attrs" 
            v-on="on"
          >
            <v-icon>
              mdi-account
            </v-icon>             
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="signOut" style="min-width:150px">
            <v-icon>
              mdi-logout
            </v-icon>             
            &nbsp;Sign Out
          </v-list-item>
        </v-list>

      </v-menu>

      <v-menu 
        v-if="!contentView && $store.state.auth.authenticated && ($vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl)"
        offset-y
      >

        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ma-2 btn" v-bind="attrs" v-on="on">
            {{ $store.state.auth.user.firstname + ' ' + $store.state.auth.user.surname }}
            <v-icon
              right
              dark
            >
              mdi-menu-down
            </v-icon>             
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="signOut" style="min-width:150px">
            <v-icon>
              mdi-logout
            </v-icon>             
            &nbsp;Sign Out
          </v-list-item>
        </v-list>

      </v-menu>

      <v-btn
        v-if="contentView"
        class="ma-2 btn"
        color="error"
        @click="exitClick"
      >
        Exit
      </v-btn>

    </v-app-bar>
    
    <v-navigation-drawer 
      v-if="($store.state.auth.authenticated) && ($store.state.auth.user.role.roleid !== 3)"
      v-model="drawer"
      app
      clipped
    >
      <v-list 
        nav
      >

        <!-- Content -->

        <v-list-item>
          <v-btn
            block
            rounded
            to="/content"
          >
            <v-icon left style="color: #34a853;">
              mdi-book-open-page-variant-outline
            </v-icon>
            Content  
            <v-spacer />
          </v-btn>
        </v-list-item> 

        <!-- Learners -->

        <v-list-item>
          <v-btn
            block
            rounded
            to="/learners"
          >
            <v-icon left style="color: #4285f4;">
              mdi-account-multiple
            </v-icon>
            Learners  
            <v-spacer />        
          </v-btn>
        </v-list-item> 

        <!-- Learner Activity -->

        <v-list-item>
          <v-btn
            block
            rounded
            to="/learneractivity"
          >
            <v-icon left style="color: #ea4335;">
              mdi-account-check
            </v-icon>
            Learner Activity  
            <v-spacer />
          </v-btn>
        </v-list-item> 

        <!-- Feedback -->

        <v-list-item
          v-if="$store.state.auth.user.role.roleid === 1"
        >
          <v-btn
            block
            rounded
            to="/feedback"
          >
            <v-icon left style="color: #000000;">
              mdi-chat
            </v-icon>
            Feedback  
            <v-spacer />
          </v-btn>
        </v-list-item> 

        <!-- Licenses -->

        <v-list-item 
          v-if="$store.state.auth.user.role.roleid === 1"
        >
          <v-btn
            block
            rounded
            to="/licenses"
          >
            <v-icon left style="color: #000000;">
              mdi-license
            </v-icon>
            Licenses  
            <v-spacer />        
          </v-btn>
        </v-list-item>

        <!-- Resources -->

        <v-list-item 
          v-if="$store.state.auth.user.role.roleid === 1"
        >
          <v-btn
            block
            rounded
            to="/resources"
          >
            <v-icon left style="color: #000000;">
              mdi-apps
            </v-icon>
            Resources  
            <v-spacer />        
          </v-btn>
        </v-list-item> 

      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view :key="$route.fullPath"></router-view>
    </v-main>

    <v-dialog
      v-model="feedbackDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="grey lighten-2">
          Feedback
        </v-card-title>

        <v-card-text>
          <v-container>
            
            <v-row>
              <v-col
                cols="12"
              >
                <span>We would love to hear your comments and suggestions on how we could improve Khula Online.<br /></span>
                <v-text-field
                  v-model="feedbackMessage"
                  label="Message"
                ></v-text-field>              
              </v-col>              
            </v-row>            
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="ma-2"     
            @click="hideFeedbackDialog"   
          >
            Cancel
          </v-btn>        

          <v-btn
            class="ma-2"  
            @click="submitFeedback()"
          >                
            Submit Feedback
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="feedbackResultDialog"
      width="500"
    >
      <v-card>
        <v-card-title class="grey lighten-2">
          {{ feedbackTitle }}
        </v-card-title>

        <v-card-text>
          <v-container>
            
            <v-row>
              <v-col
                cols="12"
              >
                <span>{{ feedbackResponse }}<br /></span>           
              </v-col>              
            </v-row>            
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="ma-2"     
            @click="feedbackResultDialog = false"   
          >
            OK
          </v-btn>        
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
import { EventBus } from '@/event-bus';
import { RepositoryFactory } from '@/repositories/RepositoryFactory'

const FeedbackRepository = RepositoryFactory.get('feedback');

export default {
  name: 'App',

  data: () => ({
    drawer: true,
    feedbackDialog: false,
    feedbackMessage: '',
    feedbackResultDialog: false,
    feedbackTitle: '',
    feedbackResponse: '',
    contentView: false
  }),

  watch: {
    '$route'() {
      this.contentView = (this.$route.path.substring(0, 10) == '/exercise/') || (this.$route.path.substring(0, 5) == '/pdf/') || (this.$route.path.substring(0, 10) == '/flipbook/');

      if (this.contentView) {
        this.drawer = false;
      }
    }
  },

  mounted() {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    var font;
    var w; // eslint-disable-line
  
    // preload fonts

    ctx.font = '10px ABC Junior Typing';		
    w = ctx.measureText(font).width; // eslint-disable-line

    ctx.font = '10px JetBrains Mono';		
    w = ctx.measureText(font).width; // eslint-disable-line    
  },

  methods: {

    exitClick() {
      EventBus.$emit('exit');
    },

    hideFeedbackDialog() {
      this.$feedback.visible = false;
      this.feedbackDialog = false;
    },

    signOut() {
      this.$store.commit('signOut'); 
      this.$router.push("/");
    },

    showFeedbackDialog() {
      this.$feedback.visible = true;
      this.feedbackDialog = true;
    },

    async submitFeedback() {

      let route = this.$router.currentRoute.path;
      let learnerId = 0;
      if (this.$store.state.learner) {
        learnerId = this.$store.state.learner.learnerid;
      }

      const { data } = await FeedbackRepository.submitMessage(learnerId, route, this.feedbackMessage);
      if (data.success) {  
          this.feedbackMessage = '';
          this.hideFeedbackDialog();
          this.feedbackTitle = 'Feedback Submitted';
          this.feedbackResponse = 'Thank you for submitting your feedback.';
          this.feedbackResultDialog = true;
      } else {
        this.feedbackTitle = 'Feedback Error';
        this.feedbackResponse = 'An unexpected error occured while submitting your feedback. Please try again later.';
        this.feedbackResultDialog = true;
      }


    },
    
  },

};
</script>

<style>
  @font-face {
    font-family: 'ABC Junior Typing';
    src: 
      local('ABC Junior Typing'), 
      url('./fonts/abcjunior.ttf');
  }
  @font-face {
    font-family: 'Courgette';
    src: 
      local('Courgette'), 
      url('./fonts/Courgette-Regular.ttf');
  }  
  @font-face {				
    font-family: 'JetBrains Mono';
    src: 
      local('JetBrains Mono'), 
      url('./fonts/JetBrainsMono-Regular.woff2');
  }	  
</style>

<style scoped>
  .frontpage {
    background: url('./assets/frontpage.jpg') no-repeat center center fixed;
    background-size: cover;
    /*background-position: center 64px;*/
  }
  .backdrop {
    background-color: #eeeeee;
  }


</style>