import Repository from '@/repositories/Repository'

export default {

    listLanguages(page, itemsPerPage, search) {
        var response = Repository.get('/language/listlanguages', {
                params:{
                    page: page,
                    itemsperpage: itemsPerPage,
                    search: search            
                } 
            });
        return response;
    },

};
