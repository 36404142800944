import Repository from '@/repositories/Repository'

export default {

    authenticate(username, password) {
        var response = Repository.post('/user/authenticate', {
            username: username,
            password: password
        });
        return response;
    },

    changePassword(userId, password) {
        var response = Repository.post('/user/changepassword', {
            userid: userId,
            password: password
        });
        return response;
    },

    /*register(username, email, password, firstname, surname) {
        var response = Repository.post('/user/register', {
            username: username,
            email: email,
            password: password,
            firstname: firstname,
            surname: surname
        });
        return response;
    },*/

};
