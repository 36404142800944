import Repository from '@/repositories/Repository'

export default {

    issueLicense(email, firstname, surname, learners, licenseStatusId, expiryTime, courseIds) {
        var response = Repository.post('/license/issuelicense', {
            email: email,
            firstname: firstname,
            surname: surname,
            learners: learners,
            licensestatusid: licenseStatusId,
            expirytime: expiryTime,
            courseids: courseIds
        });
        return response;
    },

    listLicenses(page, itemsPerPage, search) {
        var response = Repository.get('/license/listlicenses', {
                params:{
                    page: page,
                    itemsperpage: itemsPerPage,
                    search: search 
                } 
            });
        return response;
    },

    listLicenseStatuses() {
        var response = Repository.get('/license/listlicensestatuses', {
            params: { 
            }               
        });
        return response;
    },

    updateLicense(licenseId, learners, licenseStatusId, expiryTime, courseIds) {
        var response = Repository.post('/license/updatelicense', {
            licenseid: licenseId,
            learners: learners,
            licensestatusid: licenseStatusId,
            expirytime: expiryTime,
            courseids: courseIds
        });
        return response;
    },

};
