import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import { Buffer } from 'buffer';

Vue.use(Vuex)

export default new Vuex.Store({
  
  state: {
    auth: {
      authenticated: false, 
      token: '',
      user: null
    },
    learner: null,
    copiedCourseItemId: 0
  },

  getters: {
  },

  mutations: {

    copyCourseItem(state, courseItemId) {
      state.copiedCourseItemId = courseItemId;
    },

    signIn(state, token) {            
      // extract payload

      const payloadBase64 = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
      const payload = JSON.parse(Buffer.from(payloadBase64, 'base64').toString('utf8'));
      /*const payload = decodeURIComponent(window.atob(payloadBase64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));*/

      state.auth.user = payload.user;
      state.auth.token = token;
      state.auth.authenticated = true;
      state.copiedCourseItemId = 0;
    },    

    signOut(state) {
      state.auth.token = '';      
      state.auth.user = {};
      state.auth.authenticated = false;
    },

    setLearner(state, learner) {
      state.learner = learner;
    }

  },

  actions: {
  },

  modules: {
  },

  plugins: [createPersistedState({
    key: 'khula'
  })]
  
})
