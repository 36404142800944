import Repository from '@/repositories/Repository'

export default {

    appendItem(courseId, parentId, title) {
        var response = Repository.post('/course/appenditem', {
            courseid: courseId,
            parentid: parentId,
            title: title
        });
        return response;        
    },

    copyItem(sourceCourseItemId, targetCourseId, targetParentId) {
        var response = Repository.post('/course/copyitem', {
            sourcecourseitemid: sourceCourseItemId,
            targetcourseid: targetCourseId,
            targetparentid: targetParentId
        });
        return response;
    },

    deleteItem(courseId, courseItemId) {
        var response = Repository.post('/course/deleteitem', {
            courseid: courseId,
            courseitemid: courseItemId
        });
        return response;        
    },

    getChildItems(courseId, parentId, learnerId) {
        var response = Repository.get('/course/getchilditems', {
            params:{ 
                courseid: courseId,
                parentid: parentId,
                learnerid: learnerId,
            } 
        });
        return response;
    },

    getCourseHeader(courseId) {
        var response = Repository.get('/course/getcourseheader', {
            params:{ 
                courseid: courseId
            } 
        });
        return response;
    },

    getCourseItem(courseId, courseItemId) {
        var response = Repository.get('/course/getcourseitem', {
            params:{ 
                courseid: courseId,
                courseitemid: courseItemId
            } 
        });
        return response;
    },

    linkResource(courseId, courseItemId, resourceId, questionCount) {
        var response = Repository.post('/course/linkresource', {
            courseid: courseId,
            courseitemid: courseItemId,
            resourceid: resourceId,
            questioncount: questionCount,
        });
        return response;        
    },

    listCourses() {
        var response = Repository.get('/course/listcourses', {
                //params:{             
                //} 
            });
        return response;
    },

    moveItemDown(courseId, courseItemId) {
        var response = Repository.post('/course/moveitemdown', {
            courseid: courseId,
            courseitemid: courseItemId
        });
        return response;        
    },

    moveItemUp(courseId, courseItemId) {
        var response = Repository.post('/course/moveitemup', {
            courseid: courseId,
            courseitemid: courseItemId
        });
        return response;        
    },

    unlinkResource(courseId, courseItemId) {
        var response = Repository.post('/course/unlinkresource', {
            courseid: courseId,
            courseitemid: courseItemId
        });
        return response;        
    },

    updateCourseThumbnail(courseId, thumbnailBase64) {
        var response = Repository.post('/course/updatecoursethumbnail', {
            courseid: courseId,
            thumbnailbase64: thumbnailBase64
        });
        return response;
    },

    updateItem(courseId, courseItemId, title) {
        var response = Repository.post('/course/updateitem', {
            courseid: courseId,
            courseitemid: courseItemId,
            title: title
        });
        return response;        
    },

    updateItemThumbnail(courseId, courseItemId, thumbnailBase64) {
        var response = Repository.post('/course/updateitemthumbnail', {
            courseid: courseId,
            courseitemid: courseItemId,
            thumbnailbase64: thumbnailBase64
        });
        return response;
    },

};
