import Repository from '@/repositories/Repository'

export default {

    deleteResource(resourceId) {
        var response = Repository.post('/resource/deleteresource', {
            resourceid: resourceId
        });
        return response;
    },

    getResource(resourceId) {
        var response = Repository.get('/resource/getresource', {
            params:{ 
                resourceid: resourceId            
            } 
        });
        return response;
    },

    listResources(page, itemsPerPage, search, resourceTypeId) {
        var response = Repository.get('/resource/listresources', {
                params:{
                    page: page,
                    itemsperpage: itemsPerPage,
                    search: search,
                    resourcetypeid: resourceTypeId        
                } 
            });
        return response;
    },

    listResourceTypes() {
        var response = Repository.get('/resource/listresourcetypes', {
            params: { 
            }               
        });
        return response;
    },

    reuploadResource(resourceId, resourceData) {
        var response = Repository.post('/resource/reuploadresource', {
            resourceid: resourceId,
            resourcedata: resourceData
        });
        return response;
    },

    updateResource(resourceId, title, languageId) {
        var response = Repository.post('/resource/updateresource', {
            resourceid: resourceId,
            title: title,
            languageid: languageId
        });
        return response;
    },

    uploadResource(resourceId, resourceTypeId, languageId, resourceTitle, resourceData) {
        var response = Repository.post('/resource/uploadresource', {
            resourceid: resourceId,
            resourcetypeid: resourceTypeId,
            languageid: languageId,
            resourcetitle: resourceTitle,
            resourcedata: resourceData
        });
        return response;
    },

};
